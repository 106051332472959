import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ActivateDevice from './ActivateDevice.js';
import LanesStatus from './LanesStatus.js';
import LaneSpecificInfo from './LaneSpecificInfo.js';
import TopBarContent from './TopBarContent.js';
import UserProfile from './UserProfile.js';
import { signOut } from "firebase/auth";
import { auth } from "../firebase.js"; // Adjust the path as needed

function MainApp() {
  // Function to handle user logout
  const handleLogout = async () => {
    await signOut(auth);
  };

  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'dark';
    setTheme(savedTheme);
    document.body.classList.toggle('light-mode', savedTheme === 'light');
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.body.classList.toggle('light-mode', newTheme === 'light');
    localStorage.setItem('theme', newTheme);
  };

  const user = auth.currentUser; // Get the current authenticated user

  return (
    <Router>
      <div className={`wrapper ${theme}`}>
        {/* Sidebar */}
        <div className="sidebar" data-background-color="dark">
          <div className="sidebar-logo">
            <div className="logo-header" data-background-color="dark">
              <a href="index.html" className="logo">
                <img
                  src={`assets/img/kaiadmin/logo_${theme === 'light' ? 'dark' : 'light'}.svg`}
                  alt="navbar brand"
                  className="navbar-brand"
                  height="20"
                  id="logo-image"
                />
              </a>
              <div className="nav-toggle">
                <button 
                  className="btn btn-toggle toggle-sidebar" 
                  id="toggle-dark-mode" 
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  <i className="gg-menu-right"></i>
                </button>
                <button 
                  className="btn btn-toggle sidenav-toggler" 
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  <i className="gg-menu-left"></i>
                </button>
              </div>
              <button 
                className="topbar-toggler more" 
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                <i className="gg-more-vertical-alt"></i>
              </button>
            </div>
          </div>
          <div className="sidebar-wrapper scrollbar scrollbar-inner">
            <div className="sidebar-content">
              <ul className="nav nav-secondary">
                <li className="nav-item">
                  <a href="index.html">
                    <i className="fas fa-th-list"></i>
                    <p>Overview</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a data-bs-toggle="collapse" href="#pos-transactions">
                    <i className="fas fa-table"></i>
                    <p>POS Transactions</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="pos-transactions">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/pos-sale" className="nav-link">
                          <span className="sub-item">Sale</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pos-refund" className="nav-link">
                          <span className="sub-item">Refund</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pos-reversal" className="nav-link">
                          <span className="sub-item">Reversal</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pos-void" className="nav-link">
                          <span className="sub-item">Void</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pos-return" className="nav-link">
                          <span className="sub-item">Return</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/pos-cardonfile" className="nav-link">
                          <span className="sub-item">Card on File</span>
                        </Link>
                      </li>                      
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a data-bs-toggle="collapse" href="#pos-setup">
                    <i className="fas fa-desktop"></i>
                    <p>POS Setup</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="pos-setup">
                    <ul className="nav nav-collapse">
                      <li>
                        <Link to="/API-activate-device" className="nav-link">
                          <span className="sub-item">Activate Device</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/lanes-status" className="nav-link">
                          <span className="sub-item">Lanes Status</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/lane-specific-info" className="nav-link">
                          <span className="sub-item">Lane Specific Info</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>                
                <li className="nav-item">
                <Link to="/user-profile" className="nav-link">
                    <i className="fas fa-user"></i>
                    <p>My Profile</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/account-settings" className="nav-link">
                    <i className="fas fa-pen-square"></i>
                    <p>Settings</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/sales" className="nav-link">
                    <i className="far fa-chart-bar"></i>
                    <p>Sales</p>
                  </Link>
                </li>

              </ul>
            </div>
          </div>
        </div>
        {/* End Sidebar */}

        <div className="main-panel">
          <div className="main-header">
            <div className="main-header-logo">
              <div className="logo-header" data-background-color="dark">
                <a href="index.html" className="logo">
                  <img
                    src={`assets/img/kaiadmin/logo_${theme === 'light' ? 'dark' : 'light'}.svg`}
                    alt="navbar brand"
                    className="navbar-brand"
                    height="20"
                    id="logo-image"
                  />
                </a>
                <div className="nav-toggle">
                  <button 
                    className="btn btn-toggle toggle-sidebar" 
                    id="toggle-dark-mode" 
                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                    <i className="gg-menu-right"></i>
                  </button>
                  <button 
                    className="btn btn-toggle sidenav-toggler" 
                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                    <i className="gg-menu-left"></i>
                  </button>
                </div>
                <button 
                  className="topbar-toggler more" 
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                  <i className="gg-more-vertical-alt"></i>
                </button>
              </div>
            </div>
            <nav className="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Services
                      </a>
                    </li>
                  </ul>
                  <form className="d-flex">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">
                      Search
                    </button>
                  </form>
                </div>
                <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
                  <TopBarContent user={user} toggleTheme={toggleTheme} handleLogout={handleLogout} />
                </ul>
              </div>
            </nav>
          </div>

          {/* Begin Page Content */}
          <Routes>
            <Route path="/API-activate-device" element={<ActivateDevice />} />
            <Route path="/lanes-status" element={<LanesStatus />} />
            <Route path="/lane-specific-info" element={<LaneSpecificInfo />} />
            <Route path="/user-profile" element={<UserProfile />} />
          </Routes> 
          {/* End Page Content */}

          <footer className="footer">
            <div className="container-fluid d-flex justify-content-between">
                <nav className="pull-left">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link" href="http://www.ballerworks.com">
                                &copy; Copyright BallerWorks
                            </a>
                        </li>
                    </ul>
                </nav>
                <div>
                    <a target="_blank" rel="noopener noreferrer" href="https://ballerworks.com/support/">Support</a>
                    <i className="fa fa-heart heart text-danger"></i>
                </div>
            </div>
        </footer>
        </div>
      </div>
    </Router>
  );
}

export default MainApp;
