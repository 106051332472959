// src/components/LanesStatus.js
import React, { useState, useEffect } from 'react';
import { apiRequest } from '../services/triposApi';

const LanesStatus = () => {
  const [legacy, setLegacy] = useState(true);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [applicationId, setApplicationId] = useState('12345');  // Matching with Swagger
  const [applicationName, setApplicationName] = useState('sample-app-name');
  const [applicationVersion, setApplicationVersion] = useState('1.0.0');
  const [acceptorId, setAcceptorId] = useState('364805261');  
  const [accountId, setAccountId] = useState('1272525');  
  const [accountToken, setAccountToken] = useState('4E5427773ED5A2B2F36B92FE5CD3424690D63E810ED7C705DC9DA4E154EA8336A0239401');  
  const [requestId, setRequestId] = useState('12345678-1234-1234-1234-123456789123');  
  const [lanes, setLanes] = useState([]);  // Initialize as an empty array
  const [error, setError] = useState(null);
  const [requestDetails, setRequestDetails] = useState(null); 
  const [responseDetails, setResponseDetails] = useState(null); // State to hold the response details

  const [environment, setEnvironment] = useState('development');

  useEffect(() => {
    // Set the environment variable
    setEnvironment(process.env.NODE_ENV || 'development');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'tp-application-id': applicationId,
      'tp-application-name': applicationName,
      'tp-application-version': applicationVersion,
      'tp-express-acceptor-id': acceptorId,
      'tp-express-account-id': accountId,
      'tp-express-account-token': accountToken,
      'tp-request-id': requestId,
    };

    const fullUrl = `https://triposcert.vantiv.com/cloudapi/v1/lanes?legacy=${legacy}&page=${page}&pageLimit=${pageLimit}`;

    setRequestDetails({ headers, fullUrl });

    try {
      const data = await apiRequest(fullUrl, headers, 'GET');

      setLanes(Array.isArray(data) ? data : []);
      setResponseDetails({
        body: data,
        statusCode: 200, // Assuming 200 for simplicity; adjust based on actual response
        headers: {
          "content-length": JSON.stringify(data).length.toString(),
          "content-type": "application/json; charset=utf-8",
          "date": new Date().toUTCString(),
          "strict-transport-security": "max-age=31536000 ; includeSubDomains",
          "tp-request-id": requestId
        }
      });
      setError(null);
    } catch (err) {
      setError('Failed to fetch lanes.');
      setLanes([]);  // Reset lanes to empty array on error
      console.error('Error fetching lanes:', err.message);
      setResponseDetails({
        body: {},
        statusCode: err.response?.status || 500, // Fallback to 500 if no status
        headers: err.response?.headers || {}
      });
    }
  };

  return (
    <div className="container">
      <div className="page-inner">
        <div className="page-header">
          <h3 className="fw-bold mb-3">Lanes Status</h3>
          <ul className="breadcrumbs mb-3">
            <li className="nav-home">
              <a href="#">
                <i className="icon-home"></i>
              </a>
            </li>
            <li className="separator">
              <i className="icon-arrow-right"></i>
            </li>
            <li className="nav-item">
              <a href="#">POS Setup</a>
            </li>
            <li className="separator">
              <i className="icon-arrow-right"></i>
            </li>
            <li className="nav-item">
              <a href="#">Lanes Status</a>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Get Lanes Status</div>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <p>Environment: {environment}</p>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="legacy">Legacy:</label>
                    <select
                      id="legacy"
                      name="legacy"
                      value={legacy}
                      onChange={(e) => setLegacy(e.target.value === 'true')}
                      className="form-control"
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                    <br />
                    <label htmlFor="page">Page:</label>
                    <input
                      type="number"
                      id="page"
                      name="page"
                      value={page}
                      onChange={(e) => setPage(parseInt(e.target.value, 10))}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="pageLimit">Page Limit:</label>
                    <input
                      type="number"
                      id="pageLimit"
                      name="pageLimit"
                      value={pageLimit}
                      onChange={(e) => setPageLimit(parseInt(e.target.value, 10))}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="applicationId">tp-application-id:</label>
                    <input
                      type="text"
                      id="applicationId"
                      name="applicationId"
                      value={applicationId}
                      onChange={(e) => setApplicationId(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="applicationName">tp-application-name:</label>
                    <input
                      type="text"
                      id="applicationName"
                      name="applicationName"
                      value={applicationName}
                      onChange={(e) => setApplicationName(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="applicationVersion">tp-application-version:</label>
                    <input
                      type="text"
                      id="applicationVersion"
                      name="applicationVersion"
                      value={applicationVersion}
                      onChange={(e) => setApplicationVersion(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="acceptorId">tp-express-acceptor-id:</label>
                    <input
                      type="text"
                      id="acceptorId"
                      name="acceptorId"
                      value={acceptorId}
                      onChange={(e) => setAcceptorId(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="accountId">tp-express-account-id:</label>
                    <input
                      type="text"
                      id="accountId"
                      name="accountId"
                      value={accountId}
                      onChange={(e) => setAccountId(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="accountToken">tp-express-account-token:</label>
                    <input
                      type="text"
                      id="accountToken"
                      name="accountToken"
                      value={accountToken}
                      onChange={(e) => setAccountToken(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <label htmlFor="requestId">tp-request-id:</label>
                    <input
                      type="text"
                      id="requestId"
                      name="requestId"
                      value={requestId}
                      onChange={(e) => setRequestId(e.target.value)}
                      className="form-control"
                    />
                    <br />
                    <button type="submit" className="btn btn-primary">Fetch Lanes</button>
                  </form>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {lanes && lanes.length > 0 && (
                    <ul>
                      {lanes.map((lane) => (
                        <li key={lane.laneId}>
                          Lane ID: {lane.laneId} - {lane.description}
                        </li>
                      ))}
                    </ul>
                  )}
                  {requestDetails && (
                    <div>
                      <h3>Curl</h3>
                      <pre>
                        {`curl -X GET --header 'Accept: application/json' --header 'tp-application-id: ${requestDetails.headers['tp-application-id']}' --header 'tp-application-name: ${requestDetails.headers['tp-application-name']}' --header 'tp-application-version: ${requestDetails.headers['tp-application-version']}' --header 'tp-express-acceptor-id: ${requestDetails.headers['tp-express-acceptor-id']}' --header 'tp-express-account-id: ${requestDetails.headers['tp-express-account-id']}' --header 'tp-express-account-token: ${requestDetails.headers['tp-express-account-token']}' --header 'tp-request-id: ${requestDetails.headers['tp-request-id']}' --header 'Content-Type: application/json' '${requestDetails.fullUrl}'`}
                      </pre>

                      <h3>Request URL</h3>
                      <pre>{requestDetails.fullUrl}</pre>

                      {responseDetails && (
                        <>
                          <h3>Response Body</h3>
                          <pre>{JSON.stringify(responseDetails.body, null, 2)}</pre>

                          <h3>Response Code</h3>
                          <pre>{responseDetails.statusCode}</pre>

                          <h3>Response Headers</h3>
                          <pre>{JSON.stringify(responseDetails.headers, null, 2)}</pre>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanesStatus;
