// src/components/Login.js
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase"; // Adjust the path if necessary

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User logged in successfully");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-page"> {/* Apply the scoped class here */}
      <div className="login-container">
        <div className="login-box">
          <img
            src="/assets/img/logo.jpg" // Adjust the path to your logo
            alt="BallerWorks Logo"
            className="logo"
          />
          <h1>Login</h1>
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit" className="button">Sign In</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
