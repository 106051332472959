import axios from 'axios';

export const apiRequest = async (fullUrl, headers, method = 'GET', body = null) => {
  try {
    const config = {
      method,
      url: process.env.NODE_ENV === 'production' ? 'https://admin.ballerworks.com/api' : 'http://localhost:5000/api', // Adjust this based on environment
      headers: headers,
      params: { url: fullUrl }, // Pass the full URL as a query parameter
    };

    if (method === 'POST' && body) {
      config.data = body; // Attach the request body for POST requests
    }

    // Log the details of the request being made for debugging purposes
    console.log('API Request Config:');
    console.log('Full URL:', fullUrl);
    console.log('Headers:', headers);
    if (method === 'POST') {
      console.log('Request Body:', body);
    }

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error('Error making API request:', error);
    throw error;
  }
};
