import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase.js"; // Import your Firebase configuration
import Login from "./components/Login.js"; // Import the Login component
import MainApp from "./components/MainApp.js"; // Import the MainApp component
import ClipLoader from "react-spinners/ClipLoader"; // Import the ClipLoader spinner

function App() {
  // State to keep track of the authenticated user
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // New state to track loading

  // Effect to listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Set the user state when the auth state changes
      setLoading(false); // Set loading to false once the auth state is determined
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  if (loading) {
    // Render the loading spinner while checking authentication state
    return (
      <div className="loading-container">
        <ClipLoader color="#007bff" loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className="App">
      {user ? <MainApp /> : <Login />}
    </div>
  );
}

export default App;
