// src/components/UserProfile.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import placeholderImage from '../assets/img/placeholder-profile.png'; // Add a placeholder image in case user.photoURL is null

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!user) {
    return (
      <div className="container">
        <div className="page-inner">
          <div className="page-header">
            <h3 className="fw-bold mb-3">User Profile</h3>
            <ul className="breadcrumbs mb-3">
              <li className="nav-home">
                <a href="#">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="separator">
                <i className="icon-arrow-right"></i>
              </li>
              <li className="nav-item">
                <a href="#">My Profile</a>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading profile...</span>
                  </div>
                  <p>Loading profile...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }

  return (
    <div className="container">
      <div className="page-inner">
        <div className="page-header">
          <h3 className="fw-bold mb-3">User Profile</h3>
          <ul className="breadcrumbs mb-3">
            <li className="nav-home">
              <a href="#">
                <i className="icon-home"></i>
              </a>
            </li>
            <li className="separator">
              <i className="icon-arrow-right"></i>
            </li>
            <li className="nav-item">
              <a href="#">My Profile</a>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Profile Details</div>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center flex-column">
                  <div className="avatar avatar-xl mb-3">
                    <img
                      src={user.photoURL || placeholderImage}
                      alt="Profile"
                      className="avatar-img rounded-circle"
                    />
                  </div>
                  <h4 className="mb-1">{user.displayName || 'Anonymous User'}</h4>
                  <p className="text-muted">{user.email}</p>
                  {/* Additional user information can be added here */}
                  <button
                    className="btn btn-primary mt-3"
                    onClick={() => auth.signOut()}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* You can add more columns/cards here if needed */}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
