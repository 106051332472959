// src/components/ActivateDevice.js
import React, { useState } from 'react';
import { apiRequest } from '../services/triposApi';

const ActivateDevice = () => {
  const [laneData, setLaneData] = useState({
    laneId: 0,
    description: 'Sample Description',
    terminalId: 'Sample Terminal ID',
    activationCode: 'Sample Activation Code',
    marketCode: 0,
    contactlessMsdEnabled: 'false',
    contactlessEmvEnabled: 'true',
    quickChipEnabled: 'true',
    quickChipDataLifetime: 120,
    processor: 'Sample Processor',
  });

  const [headers, setHeaders] = useState({
    applicationId: 'sample-app-id',
    applicationName: 'sample-app-name',
    applicationVersion: '1.0.0',
    acceptorId: '364805261',  // Updated Default value
    accountId: '1272525',  // Updated Default value
    accountToken: '4E5427773ED5A2B2F36B92FE5CD3424690D63E810ED7C705DC9DA4E154EA8336A0239401',  // Updated Default value
    requestId: '12345678-1234-1234-1234-123456789123',  // Updated Default value
  });

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [requestDetails, setRequestDetails] = useState(null); 
  const [responseDetails, setResponseDetails] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fullUrl = `https://triposcert.vantiv.com/cloudapi/v1/lanes`;

    setRequestDetails({
      headers,
      laneData
    });

    try {
      const data = await apiRequest(fullUrl, headers, 'POST', JSON.stringify(laneData));
      setSuccess(`Lane activated successfully: ${JSON.stringify(data)}`);
      setResponseDetails({
        body: data,
        statusCode: 200,
        headers: {
          "content-length": JSON.stringify(data).length.toString(),
          "content-type": "application/json; charset=utf-8",
          "date": new Date().toUTCString(),
          "strict-transport-security": "max-age=31536000 ; includeSubDomains",
          "tp-request-id": headers.requestId
        }
      });
      setError(null);
    } catch (err) {
      setError('Failed to activate lane.');
      setSuccess(null);
      console.error('Error activating lane:', err.message);
      setResponseDetails({
        body: {},
        statusCode: err.response?.status || 500, 
        headers: err.response?.headers || {}
      });
    }
  };

  return (
    <div className="container">
      <div className="page-inner">
        <div className="page-header">
          <h3 className="fw-bold mb-3">Activate Device</h3>
          <ul className="breadcrumbs mb-3">
            <li className="nav-home">
              <a href="#">
                <i className="icon-home"></i>
              </a>
            </li>
            <li className="separator">
              <i className="icon-arrow-right"></i>
            </li>
            <li className="nav-item">
              <a href="#">POS Setup</a>
            </li>
            <li className="separator">
              <i className="icon-arrow-right"></i>
            </li>
            <li className="nav-item">
              <a href="#">Activate Device</a>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Activate Device</div>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <form onSubmit={handleSubmit}>
                    {/* Form Fields */}
                    <label>
                      Lane ID:
                      <input
                        type="number"
                        value={laneData.laneId}
                        onChange={(e) => setLaneData({ ...laneData, laneId: parseInt(e.target.value, 10) })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Description:
                      <input
                        type="text"
                        value={laneData.description}
                        onChange={(e) => setLaneData({ ...laneData, description: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Terminal ID:
                      <input
                        type="text"
                        value={laneData.terminalId}
                        onChange={(e) => setLaneData({ ...laneData, terminalId: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Activation Code:
                      <input
                        type="text"
                        value={laneData.activationCode}
                        onChange={(e) => setLaneData({ ...laneData, activationCode: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Market Code:
                      <input
                        type="number"
                        value={laneData.marketCode}
                        onChange={(e) => setLaneData({ ...laneData, marketCode: parseInt(e.target.value, 10) })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Contactless MSD Enabled:
                      <select
                        value={laneData.contactlessMsdEnabled}
                        onChange={(e) => setLaneData({ ...laneData, contactlessMsdEnabled: e.target.value })}
                        className="form-control"
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </label>
                    <br />
                    <label>
                      Contactless EMV Enabled:
                      <select
                        value={laneData.contactlessEmvEnabled}
                        onChange={(e) => setLaneData({ ...laneData, contactlessEmvEnabled: e.target.value })}
                        className="form-control"
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </label>
                    <br />
                    <label>
                      Quick Chip Enabled:
                      <select
                        value={laneData.quickChipEnabled}
                        onChange={(e) => setLaneData({ ...laneData, quickChipEnabled: e.target.value })}
                        className="form-control"
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </label>
                    <br />
                    <label>
                      Quick Chip Data Lifetime:
                      <input
                        type="number"
                        value={laneData.quickChipDataLifetime}
                        onChange={(e) => setLaneData({ ...laneData, quickChipDataLifetime: parseInt(e.target.value, 10) })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Processor:
                      <input
                        type="text"
                        value={laneData.processor}
                        onChange={(e) => setLaneData({ ...laneData, processor: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <h2>Headers</h2>
                    <label>
                      Application ID:
                      <input
                        type="text"
                        value={headers.applicationId}
                        onChange={(e) => setHeaders({ ...headers, applicationId: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Application Name:
                      <input
                        type="text"
                        value={headers.applicationName}
                        onChange={(e) => setHeaders({ ...headers, applicationName: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Application Version:
                      <input
                        type="text"
                        value={headers.applicationVersion}
                        onChange={(e) => setHeaders({ ...headers, applicationVersion: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Acceptor ID:
                      <input
                        type="text"
                        value={headers.acceptorId}
                        onChange={(e) => setHeaders({ ...headers, acceptorId: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Account ID:
                      <input
                        type="text"
                        value={headers.accountId}
                        onChange={(e) => setHeaders({ ...headers, accountId: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Account Token:
                      <input
                        type="text"
                        value={headers.accountToken}
                        onChange={(e) => setHeaders({ ...headers, accountToken: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <label>
                      Request ID:
                      <input
                        type="text"
                        value={headers.requestId}
                        onChange={(e) => setHeaders({ ...headers, requestId: e.target.value })}
                        className="form-control"
                      />
                    </label>
                    <br />
                    <button type="submit" className="btn btn-primary">Activate</button>
                  </form>
                  
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  {success && <p style={{ color: 'green' }}>{success}</p>}

                  {requestDetails && (
                    <div>
                      <h3>Curl</h3>
                      <pre>
                        {`curl -X POST --header 'Accept: application/json' --header 'tp-application-id: ${requestDetails.headers.applicationId}' --header 'tp-application-name: ${requestDetails.headers.applicationName}' --header 'tp-application-version: ${requestDetails.headers.applicationVersion}' --header 'tp-express-acceptor-id: ${requestDetails.headers.acceptorId}' --header 'tp-express-account-id: ${requestDetails.headers.accountId}' --header 'tp-express-account-token: ${requestDetails.headers.accountToken}' --header 'tp-request-id: ${requestDetails.headers.requestId}' --header 'Content-Type: application/json' --data '${JSON.stringify(requestDetails.laneData)}' 'https://triposcert.vantiv.com/cloudapi/v1/lanes'`}
                      </pre>

                      <h3>Request URL</h3>
                      <pre>
                        {`https://triposcert.vantiv.com/cloudapi/v1/lanes`}
                      </pre>

                      {responseDetails && (
                        <>
                          <h3>Response Body</h3>
                          <pre>{JSON.stringify(responseDetails.body, null, 2)}</pre>

                          <h3>Response Code</h3>
                          <pre>{responseDetails.statusCode}</pre>

                          <h3>Response Headers</h3>
                          <pre>{JSON.stringify(responseDetails.headers, null, 2)}</pre>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateDevice;
