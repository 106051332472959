import React from 'react';
import { Link } from 'react-router-dom';

function TopBarContent({ user, toggleTheme, handleLogout }) {
  return (
    <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
      <li className="nav-item topbar-icon dropdown hidden-caret d-flex d-lg-none">
        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" aria-haspopup="true">
          <i className="fa fa-search"></i>
        </a>
        <ul className="dropdown-menu dropdown-search animated fadeIn">
          <form className="navbar-left navbar-form nav-search">
            <div className="input-group">
              <input type="text" placeholder="Search ..." className="form-control" />
            </div>
          </form>
        </ul>
      </li>
      <li className="nav-item topbar-icon dropdown hidden-caret">
        <a className="nav-link dropdown-toggle" href="#" id="messageDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fa fa-envelope"></i>
        </a>
        <ul className="dropdown-menu messages-notif-box animated fadeIn" aria-labelledby="messageDropdown">
          <li>
            <div className="dropdown-title d-flex justify-content-between align-items-center">
              Messages
              <a href="#" className="small">Mark all as read</a>
            </div>
          </li>
          {/* Add message items here */}
        </ul>
      </li>
      <li className="nav-item topbar-icon dropdown hidden-caret">
        <a className="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fa fa-bell"></i>
          <span className="notification">4</span>
        </a>
        <ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
          <li>
            <div className="dropdown-title">You have 4 new notifications</div>
          </li>
          {/* Add notification items here */}
        </ul>
      </li>
      <li className="nav-item topbar-icon dropdown hidden-caret">
        <a className="nav-link" data-bs-toggle="dropdown" href="#" aria-expanded="false">
          <i className="fas fa-layer-group"></i>
        </a>
        <div className="dropdown-menu quick-actions animated fadeIn">
          <div className="quick-actions-header">
            <span className="title mb-1">Quick Actions</span>
            <span className="subtitle op-7">Shortcuts</span>
          </div>
          <div className="quick-actions-scroll scrollbar-outer">
            <div className="quick-actions-items">
              <div className="row m-0">
                <a className="col-6 col-md-4 p-0" href="#">
                  <div className="quick-actions-item">
                    <div className="avatar-item bg-danger rounded-circle">
                      <i className="far fa-calendar-alt"></i>
                    </div>
                    <span className="text">Calendar</span>
                  </div>
                </a>
                {/* Add more quick action items here */}
              </div>
            </div>
          </div>
        </div>
      </li>
                  <li className="nav-item">
                    <a href="#" id="toggle-dark-mode" className="nav-link" onClick={toggleTheme}>
                      <i className="fas fa-adjust"></i>
                    </a>
                  </li>                     
      <li className="nav-item topbar-user dropdown hidden-caret">
        <a className="dropdown-toggle profile-pic" data-bs-toggle="dropdown" href="#" aria-expanded="false">
          <div className="avatar-sm">
            <img src="assets/img/profile.jpg" alt="..." className="avatar-img rounded-circle" />
          </div>
          <span className="profile-username">
            <span className="op-7">Hi, </span>
            <span className="fw-bold" id="username-display">{user?.displayName || 'User'}</span>
          </span>
        </a>
        <ul className="dropdown-menu dropdown-user animated fadeIn">
          <div className="dropdown-user-scroll scrollbar-outer">
            <li>
              <div className="user-box">
                <div className="avatar-lg">
                  <img src="assets/img/profile.jpg" alt="image profile" className="avatar-img rounded" />
                </div>
                <div className="u-text">
                  <h4>{user?.displayName || 'User'}</h4>
                  <p className="text-muted">{user?.email || 'email@example.com'}</p>
                  <Link to="/user-profile" className="btn btn-xs btn-secondary btn-sm">View Profile</Link>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown-divider"></div>
              <Link to="/user-profile" className="dropdown-item">My Profile</Link>
              <a className="dropdown-item" href="#">My Balance</a>
              <a className="dropdown-item" href="#">Inbox</a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">Account Setting</a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="/" onClick={handleLogout}>Logout</a>
            </li>
          </div>
        </ul>
      </li>
    </ul>
  );
}

export default TopBarContent;
